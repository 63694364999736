.navmenu {
    /* position: ; */
    position: absolute;
    top: 90;
    margin-top: 10px;
    right: 10%;
    width: 200px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    z-index: 1;
}

.navmenu .items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-decoration: none;

}

.navmenu .items .profile {
    /* padding: 5px; */
    text-decoration: none;
    color: black;
}


@media screen and (max-width: 768px) {
    .navmenu {
        right: 5;
        padding: 0 0;
    }
}