@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.swiper-pagination-bullets {
  width: fit-content !important;
}

.maxwidth {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  /* @media (width<=1280px) {
    padding: 0 1.6rem;
  } */
}

.paragraph {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(91, 91, 91, 1);
}

.section-heading {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  color: rgba(51, 51, 51, 1);
  text-align: center;
}

.card-heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  color: rgba(51, 51, 51, 1);
}

.backgroundImage {
  background-image: url("./assets/bannerIameg.png");
}

.bannerOverLay {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and(max-width: 768px) {
  .bannerOverLay {
    border: 2px solid red;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  /* display: none; */
}
.calender {
  border: 1px solid rgba(234, 234, 234, 0);
  background-color: white;
  /* width: 80%; */
  box-sizing: border-box;
  outline: 0;

  position: relative;
}

.calender:focus {
  outline: none;
}

.calender::-webkit-calendar-picker-indicator {
  bottom: 0;
  background: transparent;
  position: absolute;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  right: 0;
  top: 0;
  width: auto;
}


.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}